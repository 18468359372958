import { Box, Flex, Select, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

export const DropdownInput: React.FC<any> = (props: {
  name?: string;
  title?: string;
  placeholder?: string;
  size?: "sm" | "md" | "lg";
  options: any; // Takes an enum
  styles?: any;
  defaultValue?: any;
  emptyStart?: boolean;
  disabled?: boolean;
  required?: boolean;
  uppercase?: boolean;
  onChange?: (e: any) => void;
  [key: string]: any;
}) => {
  const { defaultValue } = props;
  const [val, setVal] = useState(defaultValue || null);

  useEffect(() => {
    if (defaultValue) {
      setVal(defaultValue);
    }
  }, [defaultValue]);

  const constructValues = () => {
    const options =
      props.emptyStart ?? true
        ? [<option key="" value=""></option>]
        : ([] as any[]);
    Object.keys(props.options).forEach((key) => {
      options.push(
        <option key={key} value={key}>
          {props.options[key]}
        </option>
      );
    });

    return options;
  };

  const handleChange = (e: any) => {
    setVal(e.target.value);
    if (props.onChange) {
      props.onChange(e);
    }
  };

  return (
    <Flex flexDirection={"column"} w="100%" {...props}>
      {props.title ? (
        <Text fontWeight={600} fontSize="15px" mb="8px">
          {props.title}
          {props.required ? (
            <Text
              as={"span"}
              fontWeight="700"
              fontSize="14px"
              color="brand.green"
              ml="3px"
            >
              *
            </Text>
          ) : (
            <></>
          )}
        </Text>
      ) : (
        <></>
      )}

      <Select
        textTransform={props.uppercase ? "uppercase" : undefined}
        disabled={props.disabled}
        required={props.required}
        onChange={handleChange}
        name={props.name}
        {...props.styles}
        value={val || ""}
        size={props.size ?? "md"}
        placeholder={props.placeholder}
      >
        {constructValues()}
      </Select>
    </Flex>
  );
};
import { Box, Flex, ModalFooter, Text, useToast } from "@chakra-ui/react";
import { ButtonCustom } from "../../form/controls/form.button";
import { Modal } from "../Modal";
import { useEffect, useState } from "react";
import { FileUploadInput } from "../../form/controls/file-upload.input";
import { ellipsesText } from "../../../util/string.util";
import { errorToast } from "../../../constants/toast.constants";
import { Loading } from "../../shared/Loading";
import { TooltipCustom } from "../../form/controls/form.tooltip";
import { jobService } from "../../../service/job/job.service";
import { DropdownInput } from "../../form/controls/dropdown.input";
import { JobMediaContext } from "../../../models/media/media-context.enum";

export const JobAttachmentUploadModal = ({
  jobId,
  isOpen,
  onClose,
  onSubmit,
}) => {
  const toast = useToast();

  const [formState, setFormState] = useState<any>({ type: "OTHER" });
  const [loading, setLoading] = useState(false);
  const [uploadState, setUploadState] = useState({
    name: "",
    formData: {},
    uploaded: false,
  });

  const [fileData, setFileData] = useState(null as any);

  const onInputChange = (event: any) => {
    let { name, value } = event.target;

    if (value === "") value = null;

    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const onFileUpload = async (file: any) => {
    const formData = new FormData();

    // For ZIP files, explicitly set the content type
    if (file[0].name.toLowerCase().endsWith(".zip")) {
      // Create a new Blob with the correct MIME type
      const zipBlob = new Blob([file[0]], { type: "application/zip" });
      formData.append("file", zipBlob, file[0].name);
    } else {
      formData.append("file", file[0]);
    }

    setFileData(formData);
    setUploadState({
      name: file[0].name,
      formData: formData,
      uploaded: true,
    });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!fileData) {
      toast(errorToast("Please upload a file."));
      return;
    }

    setLoading(true);

    await jobService
      .uploadAttachment(jobId, formState.type, fileData)
      .then(() => {
        onSubmit();
        onClose();
      })
      .catch(() => {
        toast(errorToast("Error uploading attachment."));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleClose = () => {
    setFormState({});
    setUploadState({
      name: "",
      formData: {},
      uploaded: false,
    });
    setFileData(null);
    onClose();
  };

  useEffect(() => {
    return () => {};
  }, []);

  return (
    <Modal isOpen={isOpen} header="Upload Attachment" onClose={handleClose}>
      <Loading loading={loading} fill ignoreDelay />

      <form onSubmit={handleSubmit}>
        <Flex flexDir="column" gap="12px">
          <DropdownInput
            name="type"
            title="Type"
            options={JobMediaContext}
            defaultValue={"OTHER"}
            onChange={onInputChange}
          />

          <FileUploadInput
            uploadedLabel={ellipsesText(uploadState.name, 16)}
            type="document"
            onDrop={onFileUpload}
            maxFiles={1}
            isUploaded={uploadState.uploaded}
          />

          <ModalFooter>
            <Flex gap="12px">
              <ButtonCustom secondary neutral onClick={onClose}>
                Cancel
              </ButtonCustom>
              <ButtonCustom type="submit">Upload</ButtonCustom>
            </Flex>
          </ModalFooter>
        </Flex>
      </form>
    </Modal>
  );
};

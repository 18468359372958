import { Flex, Box } from "@chakra-ui/react";
import { CardCustom } from "../../../shared/CardCustom";
import { useEffect, useState } from "react";
import { UserService } from "../../../../service/user/user.service";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import SavingsOutlinedIcon from "@mui/icons-material/SavingsOutlined";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import { formatNumber } from "../../../../util/number.util";

export const AccountFinanceCard = ({}) => {
  const userService = new UserService();
  const [dashboard, setDashboard] = useState({ count: 0, totalSales: 0 });

  const init = async () => {
    const dashboard = await userService.getUserDashboard();
    if (dashboard) {
      setDashboard(dashboard);
    }
  };

  const calcRebate = () => {
    return !dashboard.totalSales ? 0 : dashboard.totalSales / 2;
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <Flex flexDir="row" gap="6px">
      <Card
        value={`${formatNumber((dashboard.count ?? 0).toFixed(2))}`}
        label="Inventory"
        icon={<AssignmentOutlinedIcon />}
      ></Card>
      <Card
        value={`$${formatNumber((dashboard.totalSales ?? 0).toFixed(2))}`}
        label="Total sales"
        icon={<MonetizationOnOutlinedIcon />}
      ></Card>
      <Card
        value={`$${formatNumber((calcRebate() ?? 0).toFixed(2))}`}
        label="Total rebate"
        icon={<SavingsOutlinedIcon />}
      ></Card>
    </Flex>
  );
};

const Card = ({ value, label, icon }) => {
  return (
    <CardCustom alignItems="center">
      {icon}
      <Box fontSize="30px" color="brand.green" fontWeight="700">
        {value}
      </Box>
      <Box color="gray" fontWeight="700">
        {label}
      </Box>
    </CardCustom>
  );
};

import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import CommentOutlinedIcon from "@mui/icons-material/CommentOutlined";
import { Box, Divider, Flex, Text, useToast } from "@chakra-ui/react";
import { convertIsoTime } from "../../../util/date.util";
import { AvatarCustom } from "../../form/avatar.custom";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import BusinessIcon from "@mui/icons-material/Business";
import ChecklistIcon from "@mui/icons-material/Checklist";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { JobAttachmentUploadModal } from "./job-attachment-upload.modal";
import { useEffect, useState } from "react";
import { ellipsesText } from "../../../util/string.util";
import DeleteIcon from "@mui/icons-material/Delete";
import { jobService } from "../../../service/job/job.service";
import { errorToast, successToast } from "../../../constants/toast.constants";
import { InventoryType } from "../../../models/enum/inventory/inventory-type.enum";
import { FlexRow } from "../../shared/FlexRow";
import InsightsIcon from "@mui/icons-material/Insights";
import TabletMacIcon from "@mui/icons-material/TabletMac";
import inventoryService from "../../../service/inventory/inventory.service";

const defaultMetrics = {
  totalReuse: 0,
  totalRecycle: 0,
  totalInventory: 0,
  totalWeight: 0,
  reuseWeight: 0,
  recycleWeight: 0,
  totalImpact: 0,
  totalSalePrice: 0,
  totalRebate: 0,
};

export const JobDetailContent = ({ job, inventory, onUpload }) => {
  const [metrics, setMetrics] = useState(defaultMetrics);

  const getMetrics = async () => {
    if (!job?.id) return;

    const resp = await inventoryService.getMetrics(undefined, job.id);
    console.log(resp);
    setMetrics(resp);
  };

  useEffect(() => {
    getMetrics();
  }, [job]);

  return (
    <>
      <Flex fontWeight="500" color="gray" flexDir="column" gap="8px">
        <Flex flexDir="row" gap="4px">
          <BusinessIcon />
          <Text
            cursor="pointer"
            textDecoration="underline"
            onClick={() =>
              window.open(
                `/app/company/${job.companyId}`,
                "_blank",
                "noopener,noreferrer"
              )
            }
          >
            {job.companyName}
          </Text>
        </Flex>
        <Flex flexDir="row" gap="4px">
          <LocationOnOutlinedIcon />
          {job.companyAddressName}
        </Flex>

        <FlexRow>
          <InsightsIcon />
          <FlexRow>
            <Text>Reuse:</Text>
            <Text>{metrics.totalReuse} items</Text>
          </FlexRow>
          ≈
          <FlexRow>
            <Text>
              {+(metrics.reuseWeight || 0).toFixed(2)}
              kg
            </Text>
          </FlexRow>
        </FlexRow>

        <FlexRow>
          <InsightsIcon />
          <FlexRow>
            <Text>Recycling:</Text>
            <Text>{metrics.totalRecycle} items</Text>
          </FlexRow>

          <Text>≈</Text>

          <FlexRow>
            <Text>
              {+(metrics.recycleWeight || 0).toFixed(2)}
              kg
            </Text>
          </FlexRow>
        </FlexRow>

        <FlexRow>
          <InsightsIcon />
          <FlexRow>
            <Text>Total:</Text>
            <Text>{metrics.totalInventory} items</Text>
          </FlexRow>
          ≈
          <FlexRow>
            <Text>
              {+(metrics.totalWeight || 0).toFixed(2)}
              kg
            </Text>
          </FlexRow>
        </FlexRow>

        <FlexRow>
          <TabletMacIcon style={{ fontSize: "16px", marginRight: "8px" }} />
          <FlexRow>
            <Text>Social Impact - Devices Donated:</Text>
            <Text>{metrics.totalImpact}</Text>
          </FlexRow>
        </FlexRow>

        <FlexRow>
          <Text>
            Total Sale Price: ${+(metrics.totalSalePrice || 0).toFixed(2)}
          </Text>
        </FlexRow>

        <FlexRow>
          <Text>Total Rebate: ${+(metrics.totalRebate || 0).toFixed(2)}</Text>
        </FlexRow>
      </Flex>

      <Divider my={4} />

      <Flex flexDir="row" justifyContent="space-between">
        <Flex flexDir="column" gap="16px" maxW="500px">
          <Flex flexDir="row" gap="100px" mb={4}>
            <CardDetail
              label="Request Date"
              value={convertIsoTime(job.startDate)}
              icon={CalendarMonthOutlinedIcon}
            />
            <PhotoCard src={job.contactPhotoUri} title="Main Contact">
              {job.contactEmail ?? "-"}
            </PhotoCard>
          </Flex>

          <Flex flexDir="row" gap="100px" mb={4}>
            <CardDetail
              label="Created Date"
              value={convertIsoTime(job.createdDate)}
              icon={CalendarMonthOutlinedIcon}
            />
            {job.assignedAdminPhotoUri && (
              <PhotoCard src={job.assignedAdminPhotoUri} title="ZOLO Champion">
                {job.assignedAdminEmail}
              </PhotoCard>
            )}
          </Flex>

          {!!job.notes && (
            <Box mb="16px">
              <CardDetail
                label="Notes"
                value={job.notes}
                icon={CommentOutlinedIcon}
              />
            </Box>
          )}
        </Flex>

        <AttachmentSection job={job} onUpload={onUpload} />
      </Flex>
    </>
  );
};

const CardDetail = ({ label, value, icon = undefined, ...props }) => {
  return (
    <Flex fontSize="14px" flexDir="column" gap="6px" {...props}>
      <Box fontWeight="500" color="gray">
        {label}
      </Box>
      <Box>{value}</Box>
    </Flex>
  );
};

const PhotoCard = ({ src, title, children }) => {
  return (
    <Flex flexDir="row" gap="12px" alignItems="center">
      <AvatarCustom border="3px solid white" src={src} widthxheight="50px" />
      <Box fontSize="14px">
        <Box fontWeight="500" color="gray">
          {title}
        </Box>
        <Box>{children}</Box>
      </Box>
    </Flex>
  );
};

const AttachmentSection = ({ job, onUpload }) => {
  const toast = useToast();
  const [isOpen, setIsOpen] = useState(false);
  const [attachments, setAttachments] = useState(job.attachments ?? []);

  const deleteAttachment = async ({ id, name }) => {
    await jobService
      .deleteAttachment(id, name)
      .then(() => {
        setAttachments((prev) => prev.filter((a) => a.id !== id));
        toast(successToast("Attachment deleted"));
      })
      .catch((e) => {
        toast(errorToast("Error deleting attachment."));
      });
  };

  useEffect(() => {
    setAttachments(job.attachments);
  }, [job.attachments]);

  return (
    <>
      <Flex
        flexDir="column"
        textAlign="right"
        alignItems="flex-end"
        maxW="400px"
      >
        <Flex
          onClick={() => setIsOpen(true)}
          cursor="pointer"
          fontWeight="500"
          textDecor="underline"
          mb={2}
        >
          Attachments - ({attachments?.length ?? 0}) <AttachFileIcon />
        </Flex>

        {attachments?.map((attachment, index) => (
          <Flex
            key={index}
            cursor="pointer"
            fontWeight="500"
            alignItems="center"
            gap={1}
          >
            <Box
              fontSize="10px"
              fontWeight="500"
              color="white"
              bg="gray"
              borderRadius="4px"
              px={2}
              h="fit-content"
            >
              {attachment.mediaContext}
            </Box>

            <Box
              textDecor="underline"
              color="blue"
              w="180px"
              onClick={() => window.open(attachment.fileUri, "_blank")}
            >
              {ellipsesText(attachment.name, 22)}
            </Box>

            <DeleteIcon
              width="18px"
              height="18px"
              cursor={"pointer"}
              style={{
                color: "gray",
              }}
              onClick={() => deleteAttachment(attachment)}
            />
          </Flex>
        ))}
      </Flex>

      <JobAttachmentUploadModal
        jobId={job.id}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onSubmit={() => {
          setIsOpen(false);
          onUpload();
        }}
      />
    </>
  );
};

import { InventoryBulkFilter } from "../../models/filter/inventory-bulk.filter";
import { InventoryModel } from "../../models/inventory.model";
import { BaseService } from "../base.service";

export class InventoryService extends BaseService {
  constructor() {
    super();
  }

  async get(id: string): Promise<InventoryModel> {
    const resp = await this.client.get(`/super/inventory?id=${id}`);
    return resp.data;
  }

  async getList(
    filter?: InventoryBulkFilter,
    hideSold?: boolean,
    page?: number,
    pageSize?: number,
    sort?: {
      accessor: string;
      direction: 0 | 1;
    }
  ): Promise<{
    data: InventoryModel[];
    totalItems: number;
    totalPage: number;
  }> {
    const resp = await this.client.post(`/super/inventory/list`, {
      filter,
      hideSold,
      page,
      pageSize,
      sort,
    });
    return resp.data;
  }

  async post(data: any): Promise<any> {
    const resp = await this.client.post(`/super/inventory`, data);
    return resp.data;
  }

  async update(data: any): Promise<any> {
    const resp = await this.client.put(`/super/inventory`, data);
    return resp.data;
  }

  async updateStatus(data: any): Promise<any> {
    const resp = await this.client.put(`/super/inventory/status`, data);
    return resp.data;
  }

  async bulkUpdate(ids: string[], data: any): Promise<any> {
    const resp = await this.client.put(`/super/inventory/bulk`, { ids, data });
    return resp.data;
  }

  async exportToCsv(filter?: InventoryBulkFilter): Promise<any> {
    const resp = await this.client.post(
      `/super/inventory/list/export`,
      { filter },
      { responseType: "blob" }
    );
    return resp.data;
  }

  async delete(id: string) {
    await this.client.post(`/super/inventory/delete`, { id });
  }

  async bulkDelete(ids: string[]) {
    await this.client.post(`/super/inventory/bulk/delete`, { ids });
  }

  async uploadInventoryCsv(
    jobId: string,
    isBulkEdit: boolean,
    data: any
  ): Promise<
    undefined | { row: number; message: string; validValues: string[] }[]
  > {
    let url = `/super/inventory/list/upload?jobId=${jobId}`;
    if (isBulkEdit) {
      url += "&isBulkEdit=true";
    }

    const resp = await this.client.post(url, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return resp?.data;
  }

  async getMetrics(companyId?: string, jobId?: string): Promise<any> {
    const resp = await this.client.post(`/super/inventory/metrics`, {
      companyId,
      jobId,
    });
    return resp.data;
  }
}

const inventoryService = new InventoryService();
export default inventoryService;

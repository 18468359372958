import { Button, Box, Flex, Text } from "@chakra-ui/react";
import { DropdownInput } from "../../form/controls/dropdown.input";

export const TableFooter = (props) => {
  const {
    totalItems,
    count,
    totalPage,
    pageNum,
    onChange,
    pageSize,
    pageSizeOptions = { 10: 10, 20: 20, 50: 50, 100: 100 }, // Default page size options
    onPageSizeChange,
  } = props;

  // Create a more consistent pagination display
  const renderPagination = () => {
    // Handle edge cases
    if (!totalPage || totalPage <= 0) return null;
    
    const currentPage = Math.min(Math.max(1, pageNum), totalPage);
    const maxButtons = 6; // Maximum buttons to display including ellipses
    
    // Create an array to hold our pagination elements
    const pages = [];
    
    // Always include first page
    pages.push({
      type: "page",
      number: 1,
      current: currentPage === 1
    });
    
    // Calculate range based on current page position
    if (totalPage <= maxButtons) {
      // If we have fewer pages than max buttons, show them all
      for (let i = 2; i <= totalPage; i++) {
        pages.push({
          type: "page",
          number: i,
          current: currentPage === i
        });
      }
    } else {
      // We need ellipses logic
      
      // Calculate the window of visible pages
      const leftSideItems = 2; // How many numbers to show on left side of current
      const rightSideItems = 3; // How many numbers to show on right side of current
      
      // Calculate start and end of our visible window
      let startPage = Math.max(2, currentPage - leftSideItems);
      let endPage = Math.min(totalPage - 1, currentPage + rightSideItems);
      
      // Adjust the window if we're near the beginning or end
      if (currentPage <= leftSideItems + 1) {
        // Near beginning, show more on right
        endPage = Math.min(totalPage - 1, maxButtons - 2);
      } else if (currentPage >= totalPage - rightSideItems) {
        // Near end, show more on left
        startPage = Math.max(2, totalPage - maxButtons + 2);
      }
      
      // Add left ellipsis if needed
      if (startPage > 2) {
        pages.push({ type: "ellipsis" });
      }
      
      // Add visible page numbers
      for (let i = startPage; i <= endPage; i++) {
        pages.push({
          type: "page",
          number: i,
          current: currentPage === i
        });
      }
      
      // Add right ellipsis if needed
      if (endPage < totalPage - 1) {
        pages.push({ type: "ellipsis" });
      }
      
      // Always include last page if we have more than one page
      if (totalPage > 1) {
        pages.push({
          type: "page",
          number: totalPage,
          current: currentPage === totalPage
        });
      }
    }
    
    // Map our logical pagination structure to React components
    return pages.map((page, index) => {
      if (page.type === "ellipsis") {
        return (
          <Button
            key={`ellipsis-${index}`}
            h="24px"
            bg="transparent"
            cursor="default"
            _hover={{ bg: "transparent" }}
            disabled
          >
            ...
          </Button>
        );
      } else {
        return (
          <Button
            key={`page-${page.number}`}
            h="24px"
            bg={page.current ? "brand.green" : "transparent"}
            color={page.current ? "white" : "brand.green"}
            _hover={{ bg: page.current ? "brand.green" : "gray.100" }}
            onClick={() => onChange(page.number)}
          >
            {page.number}
          </Button>
        );
      }
    });
  };

  // Calculate display information
  const startItem = totalItems > 0 ? (pageNum - 1) * pageSize + 1 : 0;
  const endItem = Math.min(+startItem + +pageSize - 1, totalItems);

  return (
    <Flex h="fit-content" mt={4} justifyContent="space-between" alignItems="center">
      {/* Left side - showing counts */}
      <Flex alignItems="center">
        <Text fontSize="sm">
          Showing {startItem}-{endItem} of {totalItems} items
        </Text>
      </Flex>
      
      {/* Right side - pagination controls */}
      <Flex alignItems="center" gap="16px">
        <Box>{renderPagination()}</Box>
        <Text fontSize="sm" whiteSpace="nowrap">Items per page:</Text>
        <Box w="100px">
          <DropdownInput
            size="sm"
            options={pageSizeOptions}
            defaultValue={pageSize}
            onChange={(e) => onPageSizeChange(e.target.value)}
          />
        </Box>
      </Flex>
    </Flex>
  );
};